// NPM
import React from 'react'
import { Link } from 'gatsby'
import _ from 'lodash'

const NextPosts = ({ updatesPage, posts, post }) => {
  const validPosts = posts.filter(p => p.id !== post.id)
  const sortedPosts = _.sortBy(validPosts, "publishedOn")
  const nextPosts = _.reverse(sortedPosts).slice(0,3)

  const miscTextSection = _.get(updatesPage, "sections", []).find(s => s.uuid === "updates_miscText")
  let postLinkText = "Read story"
  let nextPostsTitle = "More articles"

  if(miscTextSection) {
    postLinkText = (miscTextSection.miscText.find(mt => mt.uuid === "buttonText_postLink") || {}).text
    nextPostsTitle = (miscTextSection.miscText.find(mt => mt.uuid === "moreArticles") || {}).text
  }
  
  const renderPost = (post, postIndex) => {
    return (
      <div className="Post">
        <div className="Post__image">
          <img src={ _.get(post, "heroImage.url") } />
        </div>

        <div className="Post__content">
          <h2 className="Post__title">
            { post.title }
          </h2>
          <Link 
            className="Post__link"
            to={ `..${ post.path }` }>
              { postLinkText }
          </Link>
        </div>
      </div>
    )
  }

  return (
    <div className="NextPosts">
      <h4 className="small-header">
        { nextPostsTitle }
      </h4>

      <div className="NextPosts__posts">
        { nextPosts.map(renderPost) }
      </div>
    </div>
  )



}

export default NextPosts