// NPM
import React from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import _ from 'lodash'

// Shared components
import Description from 'src/components/Shared/Description'

// Components
import FacebookShare from './FacebookShare'
import TwitterShare from './TwitterShare'
import NextPosts from './NextPosts'

const Post = ({ updatesPage, posts, post }) => {
  return (
    <div className="PostPage">
      <h1 className="Post__title">
        { post.title }
      </h1>

      <img 
        src={ _.get(post, `heroImage.url`) }
        className="Post__heroImage"
      />

      <div className="Post__content-holder">
        <div className="Post__social">
          <TwitterShare title={ post.title } />
          <FacebookShare />
        </div>
        <ReactMarkdown
          className="Post__content"
          children={ _.get(post, `content.data.content`, '') }
          rehypePlugins={[rehypeRaw]}
        />
      </div>

      <NextPosts 
        updatesPage={ updatesPage }
        posts={ posts } 
        post={ post } 
      />
    </div>
  )
}

export default Post