// NPM
import * as React from "react"
import { graphql } from 'gatsby'
import _ from 'lodash'

// Components
import Layout from "src/layouts/fr"
import Post from "src/components/pages/Post/Post"

const PostPageFr = ({ location, data }) => {
  const topNav = data.topNav
  const post = _.get(data, `post`)
  const updatesPage = _.get(data, `updatesPage`)
  const posts = _.get(data, `posts.nodes`, [])
  const bottomNav = data.bottomNav
  const socialMedia = _.get(data, `socialMedia.nodes`, [])

  return (
    <Layout 
      location={ location }
      topNav={ topNav }
      page={ post }
      bottomNav={ bottomNav }
      socialMedia={ socialMedia }>
        <Post 
          updatesPage={ updatesPage }
          posts={ posts }
          post={ post } 
        />
    </Layout>
  )
}

export default PostPageFr

export const query = graphql`
  query PostPageFrQuery($id: String) {
    topNav: strapiTopNav(locale: {eq: "fr"}) {
      ...topNavContent
    }

    updatesPage: strapiPage(uuid: {eq: "updates"}, locale: {eq: "fr"}) {
      ...pageInformation
    }

    posts: allStrapiPost(filter: {locale: {eq: "fr"}}) {
      nodes {
        ...post
      }
    }

    post: strapiPost(id: {eq: $id}) {
      ...post
    }

    bottomNav: strapiBottomNav(locale: {eq: "fr"}) {
      ...bottomNavContent
    }

    socialMedia: allStrapiSocialMedia(filter: {locale: {eq: "fr"}}) {
      nodes {
        ...socialMedia
      }
    }
  }
`