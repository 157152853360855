// NPM
import React from 'react'

const TwitterShare = ({ title }) => {
  const share = () => {
    window.open(`https://twitter.com/intent/tweet?text=${ encodeURIComponent(title) }&url=${ window.location.href }`);
  }

  return (
    <div className="TwitterShare Post__social-button-holder">
      <button 
        onClick={ share }
        className="TwitterShare__button Post__social-button">
          <img src={ require("src/images/logos/twitter.png").default } />
      </button>
    </div>
  )

}

export default TwitterShare