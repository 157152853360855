// NPM
import React from 'react'

const FacebookShare = ({}) => {
  const share = () => {
    window.FB.ui({
      method: 'feed',
      display: 'popup',
      link: window.location.href
    })
  }

  return (
    <div className="FacebookShare Post__social-button-holder">
      <button 
        onClick={ share }
        className="FacebookShare__button Post__social-button">
          <img src={ require("src/images/logos/facebook.png").default } />
      </button>
    </div>
  )

}

export default FacebookShare